<template>
	<section class="relative overflow-hidden flex h-screen">
		<InertiaSeoHead :title="title" />
		<div
			class="relative lg:w-[40%] lg:left-0 right-[calc(100%-0px)] sm:right-[calc(100%-70px)] md:right-3/4 lg:right-[40%]"
		>
			<img src="@/assets/images/entrance.png" alt="bg image" class="min-h-screen w-full object-cover" />
			<!-- Gradient layer -->
			<div class="absolute inset-0 bg-[linear-gradient(0deg,rgba(119,51,118,0.7),rgba(119,51,118,0.7))]"></div>

			<!-- White shape -->
			<div
				class="absolute inset-0 after:absolute after:right-0 after:w-10 after:h-4/6 after:bg-white after:top-1/2 after:transform after:-translate-y-1/2"
			></div>
		</div>

		<Container class="z-10 absolute w-full h-full" fluid>
			<Row class="min-h-screen flex-nowrap w-100 h-full">
				<!-- Left Column -->
				<Column class="px-0 hidden relative md:flex items-center flex-col justify-center md:w-1/4 lg:w-[40%]">
					<Row class="hidden absolute top-0 mt-14 mx-0 lg:flex justify-start items-start w-full">
						<Column class="relative w-10/12 xl:w-2/3 mr-16">
							<div class="text-white flex justify-center items-center">
								<IconSGLogoWhite alt="SG logo" class="w-10 h-6" /> <span class="ml-2"> | </span>
								<span class="uppercase text-xl ml-2">{{ $t('Sparkling grape') }}</span>
							</div>
						</Column>
					</Row>

					<Row class="hidden absolute lg:flex justify-center items-center max-w-7xl h-auto">
						<Column class="relative w-10/12 xl:w-2/3 mr-16">
							<IconSGLogo class="absolute opacity-10" />
							<h1 class="text-j54-medium text-white font-bold mb-10 mt-5">
								{{ $t('Redefine Executive Search with Sparkling Grape!') }}
							</h1>
							<span class="text-white font-normal text-j34-regular">
								{{ $t('A pioneer Saas HUB for Technology, Digital and ESG') }}
							</span>
						</Column>
					</Row>

					<Row class="hidden absolute bottom-0 mb-14 mr-16 lg:flex justify-center items-center w-full">
						<Column class="relative w-10/12 xl:w-2/3">
							<SGToastNotification v-if="feedbackType && feedbackMsg" :message="feedbackMsg" :type="feedbackType" />
						</Column>
					</Row>
				</Column>

				<!-- Right Column -->
				<Column class="md:w-3/4 lg:w-[60%] w-full overflow-hidden">
					<Row class="h-screen flex justify-center align-center">
						<Column class="xl:px-[120px] w-full flex flex-col justify-between items-center">
							<div class="w-full h-full my-8 flex justify-center items-center">
								<slot />
							</div>
							<div class="mb-4 text-gray-400 text-sm">
								{{ rightsText }}
							</div>
						</Column>
					</Row>
				</Column>
			</Row>
		</Container>
	</section>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { InertiaSeoHead } from '@/Components/Frontend/Navigation';
import Column from '@/Components/Grid/Column.vue';
import Container from '@/Components/Grid/Container.vue';
import Row from '@/Components/Grid/Row.vue';
import SGToastNotification from '@/Components/sg-toast-notification/sg-toast-notification.component.vue';
import { IconSGLogo, IconSGLogoWhite } from '@/assets/icons/index';

defineProps({
	title: {
		type: String,
		default: null,
	},
	feedbackMsg: {
		type: String,
		default: '',
		required: false,
	},
	feedbackType: {
		type: String,
		default: '',
		required: false,
	},
});

const rightsText = computed(() => {
	const currentYear = new Date().getFullYear();
	return `©${currentYear} | Sparkling Grape - All rights reserved`;
});
</script>
